import "./TestimonialMain.css"
import IMG1 from "./testimonialHero.png"

function TestimonialMain()
{
    return(
        <div className="Testimonial-container">
            <div className="Testimonial-child1">
                <h1 className="Testimonial-childh1">TOP RATED</h1>
                <p className="Testimonial-childp">The essence of truly modern makeup is the freedom to be yourself, to express who you are !!</p>
            </div>
            <div className="Testimonial-child2">
                <img src={IMG1} alt="test" />
            </div>
            <div className="Testimonial-child3">
                <h1 className="Testimonial-childh1">100K</h1>
                <p className="Testimonial-childp">HAPPY CUSTOMERS WITH US</p>
            </div>
        </div>
    )
}

export default TestimonialMain