import "./Product.css"

function Product(props)
{
    return(
        <div className="Product-container">
            <div className="Product-heading">{props.title}</div>
            <div className="Product-description">
                <div className="Product-content">
                    <p className="Product-p">{props.pdes}</p>
                    <h1 className="Product-h1">{props.price}</h1>
                    <button className="Product-button">Shop Now</button>
                </div>
                <div className="Product-image">
                    <img className="pd-img" src={props.image} alt="item"/>
                </div>
            </div>
        </div>
    )
}

export default Product