import "./ProfileMain.css"

import Pfimg from "./pfimg.png"

function ProfileMain()
{
    return(
        <div className="ProfileMain">
            <div className="profilechild1">
                <h1 className="profilechild1h1">SKIN<br/>CARE &<br/>PROTECTION</h1>
                <div className="profilechild1bottom">
                    <h1 id="profilechild1bottomh1">Trendy<br/>Collection</h1>
                    <p id="profilechild1bottomp">The essence of truly modern makeup is the freedom to be yourself, to express who you are !!</p>
                </div>
            </div>
            <div className="profilechild2">
                <div className="profilechild2bg"> 
                    <img src={Pfimg} alt="kjbcibjb"/>
                </div>                
            </div>
            <div className="profilechild3">
                <div id="profilechild3top">
                    <h1 className="profilechild3h1">1.5m</h1>
                    <p className="profilechild3p">Monthly traffic</p>
                </div>
                <div id="profilechild3icons">
                    <div id="profilechild3icon1">
                        <i class="fa-solid fa-bag-shopping"></i>
                    </div>
                    <div id="profilechild3icon2">
                        <p>Best Signup<br/>Offers</p>
                        <div id="profilechild3icon2arrow">
                            <i class="fa-solid fa-right-long"></i>
                        </div>
                    </div>
                </div>
                <div id="profilechild3bottom">
                    <h1 className="profilechild3h1">100K</h1>
                    <p className="profilechild3p">Happy Customers</p>
                </div>                
            </div>
        </div>
    )
}

export default ProfileMain