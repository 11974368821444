import "./Review.css"

function Review(props)
{
    return(
        <div className="singlereview-container">
            <div className="image-container">
                <img src={props.image} alt="review"/>
            </div>
            <div className="comments">{props.comment}</div>
            <div className="name">{props.name}</div>
        </div>
    )

}

export default Review