import "./NavbarMain.css"
import { useState } from "react"
import IMGMAIN from "./img-1.png"

function NavbarMain()
{
    const [showmediaicons , setshowmediaicons] = useState(false);
    return(
        <div className="Navbar-container">
            <div className="mainlogo">
                <img  src={IMGMAIN} alt="main"/>&nbsp;amazon
                <div className="bar-container" onClick={() => {
                    setshowmediaicons(!showmediaicons);
                } } >
                    <div className="bar"></div>
                    <div className="bar"></div>
                    <div className="bar"></div>
                </div>
                
            </div>
            <div className= {showmediaicons ? "nav-buttonsstyle nav-buttons a" : "nav-buttons"}>
                <a href="https://www.myntra.com/" target="_blank" rel="noreferrer">Collections</a>
                <a href="https://www.myntra.com/" target="_blank" rel="noreferrer">Brands</a>
                <a href="https://www.myntra.com/" target="_blank" rel="noreferrer">New</a>
                <a href="https://www.myntra.com/" target="_blank" rel="noreferrer">Sales</a>
                <a id="ENG" href="https://www.myntra.com/" target="_blank" rel="noreferrer">ENG</a>
                <a id="inputnava" href="https://www.myntra.com/" target="_blank" rel="noreferrer"><input id="inputnav" type="text" placeholder="Search" /></a>
                <a href="https://www.myntra.com/" target="_blank" rel="noreferrer"><i class="fa-sharp fa-solid fa-cart-shopping carti"></i></a>
            </div>
        </div>
    )
}

export default NavbarMain

