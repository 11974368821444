import "./HomeMain.css"
import NavbarMain from "./Navbar/NavbarMain"
import ProfileMain from "./Profile/ProfileMain"

function HomeMain()
{
    return(
        <div className="HomeMain-container">
            <NavbarMain/>
            <ProfileMain/>
        </div>
    )
}

export default HomeMain