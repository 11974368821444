import "./App.js"
import ReviewMain from "./Componenets/Reviews/ReviewsMain.js"
import ReviewsHeading from "./Componenets/ReviewHeading/ReviewsHeading.js"
import FooterMain from "./Componenets/Footer/FooterMain.js"
import TestimonialMain from "./Componenets/Testimonial/TestimonialMain.js"
import ProductsMain from "./Componenets/Products/ProductsMain.js"
import Productsheading from "./Componenets/ProductsHeading/Productsheading.js"
import HomeMain from "./Componenets/Home/HomeMain.js"

function App()
{
    return(
        <div className="app-container">
            <HomeMain/>
            <Productsheading/>
            <ProductsMain/>
            <TestimonialMain/>
            <ReviewsHeading/>
            <ReviewMain/>
            <FooterMain/>
        </div>
    )
}

export default App