import "./FooterMain.css"
import Img1 from "./img-1.png"

function FooterMain()
{
    return(
        <div className="footer-container">
            <div className="footer-child" id="amazon-logo">
                <img id="ft-logo" src={Img1} alt="logo" />
                <p id="ft-content">amazon</p>
            </div>
            <div className="footer-child">
                <p className="ft-child-heading">Contact Us</p>
                <div className="ft-child-content">
                    <i class="fa-solid fa-location-dot "></i>
                    <p>470-D, Building No - 8, Cyberhub, Gurugram</p>
                </div>
                <div className="ft-child-content">
                    <i class="fa-solid fa-phone"></i>
                    <p>352-306-4415</p>
                </div>
                <div className="ft-child-content" id="mail">
                    <i class="fa-solid fa-envelope"></i>
                    <p>support@amazon.com</p>
                </div>
            </div>
            <div className="footer-child">
                <p className="ft-child-heading">Account</p>
                <div className="ft-child-content">
                    <i class="fa-solid fa-right-to-bracket"></i>
                    <p>Sign In</p>
                </div>
            </div>
            <div className="footer-child">
                <p className="ft-child-heading">Company</p>
                <div className="ft-child-content">
                    <i class="fa-solid fa-address-card"></i>
                    <p>About us</p>
                </div>
            </div>
            <div className="footer-child">
                <p className="ft-child-heading">Resources</p>
                <div className="ft-child-content">
                    <i class="fa-sharp fa-solid fa-shield"></i>
                    <p>Safety Privacy & Terms</p>
                </div>
            </div>
        </div>
    )
}

export default FooterMain