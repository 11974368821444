import "./ProductsMain.css"
import Product from "./Product"
import Img1 from "./img1.png"
import Img2 from "./img2.png"
import Img3 from "./img3.png"
import Img4 from "./img4.png"
import Img5 from "./img5.png"
import Img6 from "./img6.png"
import Img7 from "./img7.png"
import Img8 from "./img8.png"
import Img9 from "./img9.png"
import Img10 from "./img10.png"
import Img11 from "./img11.png"
import Img12 from "./img12.png"
import Img13 from "./img13.png"

function ProductsMain()
{
    return(
        <div className="ProductsMain-container">
            <Product title="SKIN" pdes="Super Skin Care" price="25$" image={Img1} />
            <Product title="SKIN" pdes="Super Skin Care" price="30$" image={Img2} />
            <Product title="NATURE" pdes="Super Skin Care" price="20$" image={Img3} />
            <Product title="FOUNDATION" pdes="Super Skin Care" price="25$" image={Img4} />
            <Product title="CONDITIONER" pdes="Super Skin Care" price="25$" image={Img5} />
            <Product title="NATURE" pdes="Super Skin Care" price="30$" image={Img6} />
            <Product title="CONDITIONER" price="20$" image={Img7} />
            <Product title="CONDITIONER" price="25$" image={Img8} />
            <Product title="CONDITIONER" price="30$" image={Img9} />
            <Product title="CONDITIONER" price="12$" image={Img10} />
            <Product title="FOUNDATION" price="25$" image={Img11} />
            <Product title="FOUNDATION" price="20$" image={Img12} />
            <Product title="FOUNDATION" price="30$" image={Img13} />
            <Product title="FOUNDATION" price="12$" image={Img1} />

        </div>
    )
}

export default ProductsMain