import "./ReviewsMain.css"
import Review from "./Review"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import IMG1 from "./img-1.png";
import IMG2 from "./img-2.jpg";
import IMG3 from "./img-3.jpg";

function ReviewMain()
{
    return(
        <Swiper
            
            className="review-container"
            modules={[Navigation, Pagination,Autoplay, A11y]}
            spaceBetween={50}
            
            breakpoints={{
                850: {
                  slidesPerView: 3
                },
                550:{
                  slidesPerView: 2,
                  autoplay: {delay : 2000}
                },
                100:{
                    slidesPerView: 1,
                    autoplay: {delay : 2000}
                  },
              }}
            loop={true}
            autoplay={{delay : 2000}}
            navigation
            pagination={{ clickable: true }}                        
            >
            <SwiperSlide className="review-box">
                <Review image={IMG1} comment=" Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." name="John Alendon" />
            </SwiperSlide>
            <SwiperSlide className="review-box">
                <Review image={IMG2} comment=" Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." name="Natasha" />
            </SwiperSlide>
            <SwiperSlide className="review-box">
                <Review image={IMG3} comment=" Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." name="Alex Hormonzi" />
            </SwiperSlide>
            <SwiperSlide className="review-box">
                <Review image={IMG1} comment=" Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." name="Pitbull" />
            </SwiperSlide>
            <SwiperSlide className="review-box">
                <Review image={IMG2} comment=" Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." name="Doremon" />
            </SwiperSlide>
            <SwiperSlide className="review-box">
                <Review image={IMG3} comment=" Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." name="Brad" />
            </SwiperSlide>
            <SwiperSlide className="review-box">
                <Review image={IMG1} comment=" Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum." name="Johnsena" />
            </SwiperSlide>
            
    </Swiper>
    )
}

export default ReviewMain

